import * as React from 'react';
import {
    Edit,
    TabbedForm,
    Toolbar,
    SaveButton,
    FormTab,
} from 'react-admin';

import BadgeForm from './BadgeForm';
import UserEditForm from './UserEditForm';
import Aside from './Aside';


const ProductToolbar = props => (
    <Toolbar {...props}>
        <SaveButton redirect="list" submitOnEnter={true} />
    </Toolbar>
);


const EditUser = (props: any) => {
    return (
        <Edit         
        title={"Edit User"}
        aside={<Aside />}
        undoable={false}
        component="div"
        {...props}>
            <TabbedForm toolbar={<ProductToolbar />}>
                    <FormTab label="Detail">
                        <UserEditForm {...props} />
                    </FormTab>
                    <FormTab label="Badges">
                        <BadgeForm {...props} />
                    </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default EditUser;
