import * as React from 'react';
import { SelectInput } from 'react-admin';
import { useFormState } from 'react-final-form';

import config from '../config';
const getSizes = (cat, subCat) => {
    if (subCat.sizes)
        return subCat.sizes.map(item => ({ id: item, name: item }));
    else if (cat.sizes)
        return cat.sizes.map(item => ({ id: item, name: item }));
    else return [];
};
const SizeInput = props => {
    const { values } = useFormState();
    if (!config || !values || !values.category || !values.subcategory)
        return <SelectInput choices={[]} {...props} />;
    const cat = config.categories.find(el => el.slug === values.category);
    const subCat = cat.childs.find(el => el.slug === values.subcategory);
    const choices = cat && subCat ? getSizes(cat, subCat) : [];
    return <SelectInput choices={choices} {...props} emptyText="Aucune" />;
};

export default SizeInput;
