import * as React from 'react';
import { FC } from 'react';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';

const currency = () => (
    <svg viewBox="0 0 240 80" xmlns="http://www.w3.org/2000/svg">
        <text
            x="2"
            y="80"
            style={{ fontSize: 80, color: 'primary', fontWeight: 'bolder' }}
        >
            DH
        </text>
    </svg>
);
interface Props {
    value?: number;
    label?: string;
}

const Revenue: FC<Props> = ({ value, label }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/prducts"
            icon={currency}
            title={translate(label)}
            subtitle={value ? `${value} ` : '--'}
        />
    );
};

export default Revenue;
