import * as React from 'react';
import { FC } from 'react';
import { Avatar, Link, Grid, Typography } from '@material-ui/core';
import { Customer, FieldProps } from '../../types';

interface Props extends FieldProps<Customer> {
    className?: string;
    size?: string;
}

const AvatarField: FC<Props> = ({ record, size = '25', className }) =>
    record ? (
        <Grid container direction="row" alignItems="center">
            <Avatar
                src={`${record.avatar}?size=${size}x${size}`}
                style={{
                    width: parseInt(size, 10),
                    height: parseInt(size, 10),
                    margin: 5,
                }}
                className={className}
            />
            <Grid item>
                <Link
            href={`#/prducts?displayedFilters={"created_by":true}&filter={"created_by":"${record.id}"}&order=DESC&page=1&perPage=10&sort=created_at`}
                >
                    <Typography variant="body2" color="primary">
                        {record.name}
                    </Typography>
                </Link>
                <Typography variant="body2" color="textSecondary" component="span">
                    <span
                        style={{ cursor: 'text', userSelect: 'text' }}
                        title="Click to copy"
                    >
                        {record.email}
                    </span>
                </Typography>
            </Grid>
        </Grid>
    ) : null;

export default AvatarField;
