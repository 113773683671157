import * as React from 'react';
import { FC } from 'react';
import { Product, FieldProps } from '../../types';
import Chip from '@material-ui/core/Chip';
import config from '../config';

interface Props extends FieldProps<Product> {
    className?: string;
    size?: string;
}

const CategoryField: FC<Props> = ({ record }) => {
    if (record) {
        const cat = config.categories.find(el => el.slug === record.category);
        if (cat && cat.title) return <Chip size="small" label={cat.title} />;
        else return null;
    } else return null;
};
export default CategoryField;
