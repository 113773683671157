import * as React from 'react';
import { AutocompleteArrayInput, ReferenceArrayInput } from 'react-admin';
import { useFormState } from 'react-final-form';

import ProductsSelector from '../../products/btns/ProductsSelector';
const ConditionalProductsInput = props => {
    const { values } = useFormState();
    if (values && values.type)
        return (
            <div
                style={{
                    display:
                        values && values.type === 'product' ? 'block' : 'none',
                }}
            >
                <ReferenceArrayInput {...props}>
                    <AutocompleteArrayInput
                        optionText="title"
                        setFilter={searchText => true}
                    />
                </ReferenceArrayInput>
                <ProductsSelector />
            </div>
        );
    return (
        <div
            style={{
                display: 'none',
            }}
        >
            <ReferenceArrayInput {...props}>
                <AutocompleteArrayInput optionText="title" />
            </ReferenceArrayInput>
        </div>
    );
};

export default ConditionalProductsInput;
