import React from 'react';
import { Datagrid, TextField, Filter, List, SearchInput } from 'react-admin';
const BrandFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);
const BrandList = (props: any) => {
    return (
        <List
            {...props}
            filters={<BrandFilter />}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick="edit">
                <TextField source="name" label="resources.brands.fields.name" />
            </Datagrid>
        </List>
    );
};

export default BrandList;
