import * as React from 'react';
import Chip from '@material-ui/core/Chip';
import { useTranslate } from 'react-admin';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { Record } from 'ra-core';
interface Props {
    record?: Record;
    basePath?: string;
    label?: string;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        default: {
            color: 'black',
            backgroundColor: '#efefef',
        },
    })
);
const AdminMesageTypeField = props => {
    const translate = useTranslate();
    const record = props.record;
    const classes = useStyles({});
    if (record && record[props.source]) {
        return (
            <Chip
                size="small"
                label={translate(
                    `resources.admin_messages.types.${record[props.source]}`
                )}
                className={classes.default}
            />
        );
    } else return null;
};
export default AdminMesageTypeField;
