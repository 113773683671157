import * as React from 'react';
import { FC } from 'react';
import PostAdd from '@material-ui/icons/PostAdd';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: number;
    label?: string;
}

const NbProducts: FC<Props> = ({ value, label }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/prducts"
            icon={PostAdd}
            title={translate(label)}
            subtitle={value ? `${value} ` : '--'}
        />
    );
};

export default NbProducts;
