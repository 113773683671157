const categoriesData = require('./data/categories.json');
const typesData = require('./data/types.json');
const colorsData = require('./data/colors.json');
export default {
    product_status: ['selling', 'selled', 'unpublished', 'deleted'],
    status: [
        'new_with_label',
        'new_without_label',
        'very_clean',
        'clean',
        'used',
    ],
    sizes: ['xs', 's', 'm', 'l', 'xl', 'xxl'],
    types: typesData.types,
    // Start of Colors
    colors: colorsData.colors, // end of Colors
    // start of categories
    categories: categoriesData.categories, // end of categories
};
