import * as React from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { useMediaQuery, Theme } from '@material-ui/core';
import { useTranslate, MenuItemLink, DashboardMenuItem } from 'react-admin';

import users from '../users';
import products from '../products';
import slides from '../slides';
import posts from '../posts';
import brands from '../brands';
import admin_messages from '../admin_messages';

// import DashboardMenuItem from './DashboardMenuItem';

import { AppState } from '../types';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuUsers' | 'menuDelivery';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    return (
        <div>
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            <MenuItemLink
                to={`/users`}
                primaryText={translate(`resources.users.name`, {
                    smart_count: 2,
                })}
                leftIcon={<users.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/prducts`}
                primaryText={translate(`resources.products.name`, {
                    smart_count: 2,
                })}
                leftIcon={<products.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/slides`}
                primaryText={translate(`resources.slides.name`, {
                    smart_count: 2,
                })}
                leftIcon={<slides.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/posts`}
                primaryText={translate(`resources.posts.name`, {
                    smart_count: 2,
                })}
                leftIcon={<posts.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/brands`}
                primaryText={translate(`resources.brands.name`, {
                    smart_count: 2,
                })}
                leftIcon={<brands.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/admin_messages`}
                primaryText={translate(`resources.admin_messages.name`, {
                    smart_count: 2,
                })}
                leftIcon={<admin_messages.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            {isXSmall && logout}
        </div>
    );
};

export default Menu;
