import * as React from 'react';
import { ReferenceInput, SelectInput } from 'react-admin';
import { useFormState } from 'react-final-form';

const AdminMessageSlideInput = props => {
    const { values } = useFormState();
    const showSlideInput = values && values.type && values.type === 'slide';
    return showSlideInput ? (
        <ReferenceInput
            label="resources.admin_messages.fields.slide"
            source="slide"
            reference="slides"
            filter={{ type: 'product' }}
        >
            <SelectInput optionText="title" allowEmpty />
        </ReferenceInput>
    ) : null;
};

export default AdminMessageSlideInput;
