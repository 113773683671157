import * as React from 'react';
import { Error, Loading, Query, useTranslate } from 'react-admin';
import { DateField } from 'react-admin';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
} from '@material-ui/core';
import UserField from '../fields/UserField';
import EventTYpeField from '../fields/EventTypeField';
import PriceField from '../fields/PriceField';
const Events = (props: any) => {
    const id = props.id;
    const translate = useTranslate();
    return (
        <Query
            type="getMany"
            resource={`prducts/${id}/events`}
            payload={{
                $sort: { created_at: 'ASC' },
            }}
        >
            {({ data, total, loading, error }) => {
                if (loading) {
                    return <Loading />;
                }
                if (error) {
                    console.log(error);
                    return <Error />;
                }
                console.log(data);
                return (
                    <div style={{ margin: '1em' }}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            {translate(
                                                'resources.events.fields.type'
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {translate(
                                                'resources.events.fields.created_at'
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {translate(
                                                'resources.events.fields.by'
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {translate(
                                                'resources.events.fields.to'
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            {translate(
                                                'resources.events.fields.price'
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map(e => (
                                        <TableRow key={e._id}>
                                            <TableCell>
                                                <EventTYpeField record={e} />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {translate(
                                                    `resources.events.types.${e.type}`
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <DateField
                                                    record={e}
                                                    source="created_at"
                                                    showTime={true}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <UserField record={e.by} />
                                            </TableCell>
                                            <TableCell>
                                                <UserField record={e.to} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <PriceField
                                                    record={e}
                                                    source="price"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                );
            }}
        </Query>
    );
};
const ProductHistory = props => <Events id={props.id} {...props} />;
export default ProductHistory;
