import * as React from 'react';
import { FC } from 'react';
import { Product, FieldProps } from '../../types';
import { Typography } from '@material-ui/core';
interface Props extends FieldProps<Product> {
    decimalCount?: number;
    decimal?: string;
    thousands?: string;
    currency?: string;
}

const PriceField: FC<Props> = ({
    source = '',
    record = {},
    decimalCount = 2,
    decimal = '.',
    thousands = ' ',
    currency = 'MAD',
}) => {
    return record ? (
        <Typography variant="body2" align="right" color="textPrimary">
            {`${formatMoney(
                record[source],
                decimalCount,
                decimal,
                thousands
            )} ${currency}`}
        </Typography>
    ) : null;
};
const formatMoney = (
    amount,
    decimalCount = 2,
    decimal = '.',
    thousands = ' '
) => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? '-' : '';

        let i = parseInt(
            (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
            negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
            (decimalCount
                ? decimal +
                  Math.abs(Number(amount) - Number(i))
                      .toFixed(decimalCount)
                      .slice(2)
                : '')
        );
    } catch (e) {
        console.log(e);
    }
};
export default PriceField;
