import slideCart from '@material-ui/icons/Slideshow';

import SlideList from './SlideList';
import SlideEdit from './SlideEdit';
import SlideCreate from './SlideCreate';

export default {
    list: SlideList,
    edit: SlideEdit,
    create: SlideCreate,
    icon: slideCart,
};
