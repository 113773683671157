import * as React from 'react';
import { FC } from 'react';
import { Product, FieldProps } from '../../types';
import Chip from '@material-ui/core/Chip';

import config from '../config';
interface Props extends FieldProps<Product> {
    className?: string;
    size?: string;
}

const TypeField: FC<Props> = ({ record }) => {
    if (record) {
        const obj = config.types.find(t => t.name === record.type) || {
            label: 'N/A',
        };
        return <Chip size="small" label={obj.label} />;
    } else return null;
};
export default TypeField;
