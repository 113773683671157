import * as React from 'react';
import { SelectInput } from 'react-admin';
import { useFormState } from 'react-final-form';

import config from '../config';
const toChoices = items =>
    items.map(item => {
        const cat = config.categories.find(el => el.slug === item);
        return cat ? { id: cat.slug, name: cat.title } : null;
    });
const CatInput = props => {
    const { values } = useFormState();
    if (!config || !values || !values.type)
        return <SelectInput choices={[]} {...props} />;
    const type = config.types.find(t => t.name === values.type);
    const choices = type ? toChoices(type.categories) : [];
    return <SelectInput choices={choices} {...props} emptyText="Tout" />;
};

export default CatInput;
