import * as React from 'react';
import { SelectInput, useTranslate } from 'react-admin';

import config from '../config';
const toChoices = (items, translate) =>
    items.map(item => ({
        id: item.name,
        name: item.label,
    }));
const TypeInput = props => {
    const translate = useTranslate();

    if (!config) return <SelectInput choices={[]} {...props} />;
    return (
        <SelectInput
            choices={toChoices(config.types, translate)}
            {...props}
            emptyText="Tout"
        />
    );
};

export default TypeInput;
