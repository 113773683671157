import * as React from 'react';
import { SelectInput, useTranslate } from 'react-admin';

const toChoices = (items, translate) =>
    items.map(item => ({
        id: item,
        name: translate(`resources.admin_messages.types.${item}`),
    }));
const AdminMesageTypeInput = props => {
    const translate = useTranslate();
    const values = ['standard', 'slide'];
    return (
        <SelectInput
            choices={toChoices(values, translate)}
            {...props}
            emptyText="Tout"
        />
    );
};

export default AdminMesageTypeInput;
