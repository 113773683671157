import * as React from 'react';
import { Card, CardContent, Box, Typography } from '@material-ui/core';
import {
    DateInput,
    SelectInput,
    useTranslate,
    FormDataConsumer,
    required,
} from 'react-admin';

const BadgeForm = props => {
    const translate = useTranslate();

    const choices = [
        {
            id: 'gold',
            name: 'Gold',
            image: '/src/users/bagdeIcons/1st-prize.png',
        },
        {
            id: 'silver',
            name: 'Silver',
            image: '/src/users/bagdeIcons/2nd-place.png',
        },
        {
            id: 'basic',
            name: 'Basic',
            image: '/src/users/bagdeIcons/3rd-place.png',
        },
        {
            id: '',
            name: 'Non badge',
        },
    ];
    return (
        <Card>
            <form>
                <CardContent>
                    <Box display={{ md: 'block', lg: 'flex' }}>
                        <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                            <Typography variant="h6" gutterBottom>
                                {translate('resources.users.fields.badge')}
                            </Typography>
                            <SelectInput
                                source="badge"
                                label="Badge"
                                choices={choices}
                                emptyText="Non badge"
                                resettable
                                fullWidth
                                format={value => value || ''}
                                parse={value => value || null}
                            />

                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    formData.badge && (
                                        <Box
                                            display={{
                                                xs: 'block',
                                                sm: 'flex',
                                            }}
                                        >
                                            <Box
                                                flex={1}
                                                mr={{ xs: 0, sm: '0.5em' }}
                                            >
                                                <DateInput
                                                    source="periodStart"
                                                    resettable
                                                    label="Début de la période"
                                                    fullWidth
                                                    validate={required()}
                                                />
                                            </Box>
                                            <Box
                                                flex={1}
                                                ml={{ xs: 0, sm: '0.5em' }}
                                            >
                                                <DateInput
                                                    source="periodEnd"
                                                    label="Fin de période"
                                                    fullWidth
                                                    validate={required()}
                                                />
                                            </Box>
                                        </Box>
                                    )
                                }
                            </FormDataConsumer>
                        </Box>
                    </Box>
                </CardContent>
            </form>
        </Card>
    );
};

export default BadgeForm;
