import * as React from 'react';
import { SelectInput } from 'react-admin';
import { useFormState } from 'react-final-form';

import config from '../config';
const toChoices = items =>
    items.map(item => ({ id: item.slug, name: item.title }));
const SubCatInput = props => {
    const { values } = useFormState();
    if (!config || !values || !values.category)
        return <SelectInput choices={[]} {...props} />;
    const cat = config.categories.find(el => el.slug === values.category);
    const choices = cat ? toChoices(cat.childs) : [];
    return <SelectInput choices={choices} {...props} emptyText="Tout" />;
};

export default SubCatInput;
