import * as React from 'react';
import { TextInput } from 'react-admin';
import { useFormState } from 'react-final-form';

const ConditionalUrlInput = props => {
    const { values } = useFormState();
    if (values && values.type)
        return (
            <TextInput
                {...props}
                style={{
                    display: values && values.type === 'url' ? 'block' : 'none',
                }}
                placeholder="http://monsite.com/exemple"
            />
        );
    return (
        <TextInput
            {...props}
            style={{
                display: 'none',
            }}
        />
    );
};

export default ConditionalUrlInput;
