import * as React from 'react';
import { ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import { Box } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import UsersSelector from '../btns/UsersSelector';
const AdminMessageTargetUsersInput = props => {
    const { values } = useFormState();
    if (!values || !values.target || values.target !== 'users') return null;
    return (
        <Box display="bloc" alignItems="center">
            <UsersSelector />
            <ReferenceArrayInput {...props}>
                <AutocompleteArrayInput optionText="name" />
            </ReferenceArrayInput>
        </Box>
    );
};

export default AdminMessageTargetUsersInput;
