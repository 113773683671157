import * as React from 'react';
import { FC } from 'react';
import {
    Create,
    TextInput,
    RadioButtonGroupInput,
    BooleanInput,
    TextField,
    Toolbar,
    FormWithRedirect,
    required,
    minLength,
    maxLength,
    choices,
    useTranslate,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import { Slide, FieldProps } from '../types';
import ConditionalUrlInput from './inputs/ConditionalUrlInput';
import ConditionalProductsInput from './inputs/ConditionalProductsInput';
import MyImageInput from '../inputs/MyImageInput';

const SlideCreate = (props: any) => {
    return (
        <Create title={<SlideTitle />} component="div" {...props}>
            <SlideForm />
        </Create>
    );
};

const SlideTitle: FC<FieldProps<Slide>> = ({ record }) =>
    record ? <TextField record={record} size="32" /> : null;

const SlideForm = (props: any) => {
    const translate = useTranslate();
    const validatetitle = [required(), minLength(1), maxLength(30)];
    const validateDescription = [required(), minLength(1), maxLength(100)];
    const validateType = choices(['url', 'product']);
    const validateImage = [required()];
    const validateURl = [minLength(3)];

    const validateSlide = values => {
        const errors: any = {};
        if (!values.type) {
            errors.type = [
                translate('resources.slides.validation.type.required'),
            ];
        } else if (
            values.type === 'url' &&
            (!values.url || values.url.length < 1)
        ) {
            errors.url = [
                translate('resources.slides.validation.url.required'),
            ];
        } else if (
            values.type === 'product' &&
            (!values.products || values.products.length < 1)
        ) {
            errors.products = [
                translate('resources.slides.validation.products.required'),
            ];
        }
        return errors;
    };
    return (
        <FormWithRedirect
            {...props}
            validate={validateSlide}
            redirect="list"
            mutators={{
                setProducts: (args, state, utils) => {
                    utils.changeValue(state, 'products', () => args[0]);
                },
            }}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <TextInput
                                        type="title"
                                        source="title"
                                        resource="slides"
                                        label="resources.slides.fields.title"
                                        validate={validatetitle}
                                        fullWidth
                                    />
                                    <BooleanInput
                                        label="resources.slides.fields.is_published"
                                        source="is_published"
                                    />
                                    <MyImageInput
                                        validate={validateImage}
                                        source="image"
                                        label="Image"
                                        accept="image/*"
                                    />
                                    <TextInput
                                        multiline
                                        type="description"
                                        source="description"
                                        resource="slides"
                                        label="resources.slides.fields.description"
                                        validate={validateDescription}
                                        fullWidth
                                    />
                                    <RadioButtonGroupInput
                                        source="type"
                                        label="resources.slides.fields.type"
                                        validate={validateType}
                                        choices={[
                                            {
                                                id: 'url',
                                                name:
                                                    'resources.slides.type.url',
                                            },
                                            {
                                                id: 'product',
                                                name:
                                                    'resources.slides.type.product',
                                            },
                                        ]}
                                    />
                                    <ConditionalUrlInput
                                        validate={validateURl}
                                        type="url"
                                        source="url"
                                        resource="slides"
                                        label="resources.slides.fields.url"
                                        fullWidth
                                    />
                                    <ConditionalProductsInput
                                        resource="slides"
                                        label="resources.slides.type.product"
                                        source="products"
                                        reference="prducts"
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="slides"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

export default SlideCreate;
