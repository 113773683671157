import * as React from 'react';
import { SelectInput, useTranslate } from 'react-admin';

import config from '../config';
const toChoices = (items, translate) =>
    items.map(item => ({
        id: item,
        name: translate(`resources.products.product_status.${item}`),
    }));
const ProductStatusInput = props => {
    const translate = useTranslate();
    if (!config) return <SelectInput choices={[]} {...props} />;
    return (
        <SelectInput
            choices={toChoices(config.product_status, translate)}
            {...props}
            emptyText="Tout"
        />
    );
};

export default ProductStatusInput;
