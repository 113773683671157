import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    Filter,
    List,
    BooleanInput,
} from 'react-admin';
import AdminMesageTypeField from './fields/AdminMesageTypeField';
import ColoredBooleanField from '../users/ColoredBooleanField';

const AdminMessageFilter = (props: any) => (
    <Filter {...props}>
        <BooleanInput
            source="is_sent"
            label="resources.posts.fields.is_published"
        />
    </Filter>
);
const AdminMessageList = (props: any) => {
    return (
        <List
            {...props}
            filters={<AdminMessageFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
        >
            <Datagrid optimized rowClick="edit">
                <AdminMesageTypeField
                    source="type"
                    label="resources.admin_messages.fields.type"
                />
                <TextField
                    source="message"
                    label="resources.admin_messages.fields.message"
                />
                <ColoredBooleanField
                    source="is_sent"
                    label="resources.admin_messages.fields.is_sent"
                />
                <DateField
                    source="last_sent_at"
                    type="date"
                    label="resources.admin_messages.fields.last_sent_at"
                />
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.admin_messages.fields.created_at"
                />
            </Datagrid>
        </List>
    );
};

export default AdminMessageList;
