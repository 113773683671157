import * as React from 'react';
import { Button, useUnselectAll } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';

const AddToSlideBtn = ({ selectedIds }) => {
    const { values } = useFormState();
    const unselectAll = useUnselectAll();
    const form = useForm();
    const addProductsToLists = () => {
        if (values && values.products)
            form.mutators.setProducts([...values.products, ...selectedIds]);
        else form.mutators.setProducts([...selectedIds]);
        unselectAll('prducts');
    };

    return (
        <Button
            label="Ajouter les produits"
            onClick={addProductsToLists}
        ></Button>
    );
};

export default AddToSlideBtn;
