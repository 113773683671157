import * as React from 'react';
import { FC } from 'react';
import {
    Create,
    TextInput,
    BooleanInput,
    TextField,
    Toolbar,
    FormWithRedirect,
    required,
    minLength,
    maxLength,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';
import { Post, FieldProps } from '../types';
import MyImageInput from '../inputs/MyImageInput';

const PostCreate = (props: any) => {
    return (
        <Create title={<PostTitle />} component="div" {...props}>
            <PostForm />
        </Create>
    );
};

const PostTitle: FC<FieldProps<Post>> = ({ record }) =>
    record ? <TextField record={record} size="32" /> : null;

const PostForm = (props: any) => {
    const validatetitle = [required(), minLength(1), maxLength(30)];
    const validateDescription = [required(), minLength(1), maxLength(100)];
    const validateContent = [required(), minLength(1), maxLength(1000)];
    const validateImage = [required()];
    const validatePublished = [required()];

    return (
        <FormWithRedirect
            {...props}
            redirect="list"
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <BooleanInput
                                        type="is_published"
                                        source="is_published"
                                        resource="posts"
                                        label="resources.posts.fields.is_published"
                                        validate={validatePublished}
                                    />
                                    <TextInput
                                        type="title"
                                        source="title"
                                        resource="posts"
                                        label="resources.posts.fields.title"
                                        validate={validatetitle}
                                        fullWidth
                                    />
                                    <MyImageInput
                                        validate={validateImage}
                                        source="image"
                                        label="resources.posts.fields.image"
                                        accept="image/*"
                                    />
                                    <TextInput
                                        multiline
                                        type="short_description"
                                        source="short_description"
                                        resource="posts"
                                        label="resources.posts.fields.short_description"
                                        validate={validateDescription}
                                        fullWidth
                                    />
                                    <TextInput
                                        multiline
                                        type="content"
                                        source="content"
                                        resource="posts"
                                        label="resources.posts.fields.content"
                                        validate={validateContent}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="posts"
                        />
                    </form>
                </Card>
            )}
        />
    );
};

export default PostCreate;
