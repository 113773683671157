import React, { Fragment } from 'react';
import {
    useTranslate,
    Datagrid,
    DateField,
    TextField,
    NumberField,
    Filter,
    List,
    SearchInput,
    TextInput,
    AutocompleteInput,
    ReferenceInput,
    ReferenceField,
    downloadCSV,
} from 'react-admin';

import jsonExport from 'jsonexport/dist';

import Thumbnailfield from './fields/ThumbnailField';
import CategoryField from './fields/CatField';
import PriceField from './fields/PriceField';
import TypeField from './fields/TypeField';
import ProductStatusField from './fields/ProductStatusField';
import CatInput from './inputs/CatInput';
import SubCatInput from './inputs/SubCatInput';
import TypeInput from './inputs/TypeInput';
import StatusInput from './inputs/StatusInput';
import ProductStatusInput from './inputs/ProductStatusInput';
import ColorInput from './inputs/ColorInput';

import PublishBulkButton from './btns/PublishBulkButton';
import UnpublishBulkButton from './btns/UnpublishBulkButton';

// Filters
const ProductFilter = (props: any) => {
    const { filterValues, setFilters } = props;
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <TypeInput
                source="type"
                label="resources.products.fields.type"
                onChange={e => {
                    if (filterValues.category)
                        setFilters({
                            ...filterValues,
                            type: e.target.value,
                            category: null,
                            subcategory: null,
                        });
                }}
            />
            <CatInput
                source="category"
                label="resources.products.fields.category"
                emptyText="Tout"
                onChange={e => {
                    if (filterValues.subcategory)
                        setFilters({
                            ...filterValues,
                            category: e.target.value,
                            subcategory: null,
                        });
                }}
            />
            <SubCatInput
                source="subcategory"
                label="resources.products.fields.subcategory"
            />
            <ReferenceInput
                label="resources.products.fields.created_by"
                source="created_by"
                reference="users"
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput
                label="resources.products.fields.selled_to"
                source="selled_to"
                reference="users"
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <TextInput label="resources.products.fields.brand" source="brand" />
            <ProductStatusInput
                source="product_status"
                label="resources.products.product_status.title"
            />
            <StatusInput
                source="status"
                label="resources.products.fields.status"
            />
            <ColorInput
                source="color"
                label="resources.products.fields.color"
            />
        </Filter>
    );
};
// bulk action Buttons
const ProductBulkActionButtons = props => (
    <Fragment>
        <PublishBulkButton label="" {...props} />
        <UnpublishBulkButton label="" {...props} />
    </Fragment>
);

const exporter = products => {
    const APP_URL = process.env.REACT_APP_PUBLIC_URL;
    const productsForExport = products.map(product => {
        let created_at = new Date(product.created_at).toLocaleDateString(
            'fr-FR',
            {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            }
        );
        return {
            id: product.id,
            title: product.title,
            category: product.category,
            seller: `${APP_URL}/#/users/${product.created_by}`,
            price: product.sellingPrice,
            type: product.type,
            status: product.status,
            created_at: created_at,
            count_offers: product.count_requests,
            is_selled: product.is_selled,
        };
    });
    jsonExport(productsForExport, {}, (err, csv) => {
        downloadCSV(csv, 'products'); // download as 'posts.csv` file
    });
};

const ProductList = (props: any) => {
    const translate = useTranslate();
    return (
        <List
            {...props}
            title={translate('resources.products.plural')}
            filters={<ProductFilter />}
            bulkActionButtons={<ProductBulkActionButtons />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            exporter={exporter}
        >
            <Datagrid optimized rowClick="edit">
                <Thumbnailfield />
                <TextField source="id" label="resources.products.fields.id" />
                <TextField
                    source="title"
                    label="resources.products.fields.title"
                />
                <CategoryField label="resources.products.fields.category" />

                <ReferenceField
                    label="resources.products.fields.created_by"
                    source="created_by"
                    reference="users"
                >
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField
                    label="resources.products.fields.created_by_email"
                    source="created_by"
                    reference="users"
                >
                    <TextField source="email" />
                </ReferenceField>
                <DateField source="created_at" type="date" />
                <PriceField
                    source="sellingPrice"
                    label="resources.products.fields.sellingPrice"
                />
                <NumberField
                    source="calls_count"
                    label="resources.products.fields.calls_count"
                />

                <TypeField label="resources.products.fields.type" />
                <ProductStatusField label="resources.products.product_status.title" />
            </Datagrid>
        </List>
    );
};

export default ProductList;
