import { TranslationMessages } from 'ra-core';
import frenchMessages from 'ra-language-french';

const customFrenchMessages: TranslationMessages = {
    ...frenchMessages,
    global: {
        auth: {
            email: 'Email',
            sign_in: 'Entrer',
            password: 'Mot de Passe',
            sign_in_error: "Erreur d'Authentification Failed",
        },
    },
    pos: {
        search: 'Rechercher',
        configuration: 'Configuration',
        language: 'Langue',
        theme: {
            name: 'Theme',
            light: 'Clair',
            dark: 'Obscur',
        },
        dashboard: {
            subscriptions: {
                title: "Nombre d'inscriptions",
                today: "Aujourd'hui",
                week: 'Cette Semaine',
                month: 'Ce Mois',
            },
            products: {
                title: 'Nombre de Produits Rajoutés',
                today: "Aujourd'hui",
                week: 'Cette Semaine',
                month: 'Ce Mois',
            },
            selles: {
                title: 'Nombre de Produits Vendus',
                today: "Aujourd'hui",
                week: 'Cette Semaine',
                month: 'Ce Mois',
            },
            ca: {
                title: "Chiffre d'Affaire en DH",
                today: "Aujourd'hui",
                week: 'Cette Semaine',
                month: 'Ce Mois',
            },

            month_history: "Chiffre d'affaire sur 30 jours",
            new_orders: 'Nouvelles commandes',
            pending_reviews: 'Commentaires à modérer',
            new_customers: 'Nouveaux clients',
            pending_orders: 'Commandes à traiter',
            order: {
                items:
                    'par %{customer_name}, un produit |||| par %{customer_name}, %{nb_items} produits',
            },
            welcome: {
                title: 'Bienvenue dans PRIM',
                subtitle: 'Ceci est le back-office PRIM..',
                aor_button: 'Site web de PRIM',
                demo_button: 'Code source de cette démo',
            },
        },
        menu: {
            sales: 'Ventes',
            catalog: 'Catalogue',
            users: 'Utilisateurs',
            delivery: 'Livraisons',
        },
    },
    resources: {
        users: {
            name: 'Client |||| Clients',
            fields: {
                name: 'Nom Complet',
                email: 'Email',
                phone: 'Tél',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                birthdate: 'date de Naissance',
                badges: 'Badges',
                badge: 'Badge',
                periodStart: 'Date de début',
                periodEnd: 'Date de fin',
                socialReason: 'Raison Sociale',
                created_at: "Date d'inscription",
                updated_at: 'Dernière mise à jour',
                accountEnabled: 'Activé',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
                count_products: 'Nombre de Produits',
                count_selled_products: 'Nombre de Produits Vendus',
                count_bought_products: 'Nombre de Produits Achetés',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                products: 'Produits',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        professionals: {
            name: 'Professional |||| Professionals',
            fields: {
                firstName: 'Prénom',
                lastName: 'Nom',
                etablissement: 'Etablissement',
                email: 'Email',
                phone: 'Tél',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                socialReason: 'Raison Sociale',
                created_at: 'Crée le',
                updated_at: 'Dernière mise à jour',
                accountEnabled: 'Activé',
                image: 'Image',
                commands: 'Commandes',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',

                services: {
                    name: 'Name',
                    quantity: 'Quantity',
                    price: 'Price',
                    total: 'Total',
                    category: 'Category',
                    subCategory: 'Sub-Category',
                    gender: 'Gender',
                },
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                collaborators: 'Collaborateurs',
                services: 'Services',
                openingTimes: "Heures d'ouverture",
                orders: 'Prestations',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        admins: {
            name: 'Administrateur |||| Administrateurs',
            fields: {
                firstName: 'Prénom',
                lastName: 'Nom',
                email: 'Email',
                phone: 'Tél',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                socialReason: 'Raison Sociale',
                created_at: 'Crée le',
                updated_at: 'Dernière mise à jour',
                accountEnabled: 'Activé',
                commands: 'Commandes',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        orders: {
            name: 'Commande |||| Commandes',
            amount: '1 commande |||| %{smart_count} commandes',
            title: 'Commande n°%{reference}',
            fields: {
                created_at: 'Crée le',
                updated_at: 'Dernière mise à jour',
                code: 'Code',
                date: 'Date',
                day: 'Jour',
                hour: 'Heure',
                user: 'Client',
                professional: 'Professionnel',
                paymentMode: 'Mode de paiement',
                total: 'Montant',
                totalOrder: 'Montant Total',
                isClientPaid: 'Payé',
                isProfessionalPaid: 'Soldé',
                payProfessional: 'Solder',
                new: 'Nouvelles',
                approuved_by_client: 'Approuvées',
                finished_by_professional: 'Terminées',
                cancelled_by_client: 'Annullées',
                declined_by_client: 'Déclinées',
                notSolded: 'Total du Solde',
                services: {
                    name: 'Nom',
                    quantity: 'Quantité',
                    price: 'Prix Unitaire',
                    total: 'Montant',
                    category: 'Catégorie',
                    subCategory: 'Sous-Catégorie',
                    gender: 'Genre',
                },
                customer_id: 'Client',
                date_gte: 'Emises depuis',
                date_lte: 'Emises avant',
                nb_items: 'Nb articles',
                reference: 'Référence',
                returned: 'Annulée',
                status: 'Etat',
                total_gte: 'Montant minimum',
            },
            notification: {
                payment_success: 'Paiement effectué avec succès',
                payment_error: 'Error de paiement',
            },
        },
        invoices: {
            name: 'Facture |||| Factures',
            fields: {
                id: 'Numéro',
                date: 'Date de facture',
                customer_id: 'Client',
                command_id: 'Commande',
                date_gte: 'Emises depuis',
                date_lte: 'Emises avant',
                address: 'Adresse',
                total_ex_taxes: 'Montant HT',
                delivery_fees: 'Frais de livraison',
                taxes: 'TVA',
            },
        },
        services: {
            name: 'Service |||| Services',
            fields: {
                reference: 'Reference',
                name: 'Nom',
                brand: 'Marque',
                packaging: 'Conditionnement',
                salePackaging: 'Conditionnement à la vente',
                tva: 'TVA',
                recommended: 'Recommander',
                shortDescription: 'Courte Description',
                longDescription: 'Longue Description',
                stock: 'Stock',
                category: 'Catégorie',
                subCategory: 'Sous-Catégorie',
                gender: 'Genre',
                image: 'Image',
            },
            tabs: {
                image: 'Image',
                details: 'Détails',
                shortDescription: 'Courte Description',
                longDescription: 'Longue Description',
                reviews: 'Commentaires',
                features: 'Features',
            },
            image: {
                upload_several:
                    'Glissez des photos à uploader ou cliquez pour en sélectionner une.',
                upload_single:
                    'Glissez une photo à uploader ou cliquez pour la sélectionner.',
            },
        },
        categories: {
            name: 'Catégorie |||| Catégories',
            fields: {
                name: 'Nom',
                image: 'Image',
                products: 'Produits',
            },
            image: {
                upload_several:
                    'Glissez des photos à uploader ou cliquez pour en sélectionner une.',
                upload_single:
                    'Glissez une photo à uploader ou cliquez pour la sélectionner.',
            },
        },
        features: {
            name: 'Feature |||| Features',
            fields: {
                name: 'Nom',
                image: 'Image',
                products: 'Produits',
            },
            image: {
                upload_several:
                    'Glissez des photos à uploader ou cliquez pour en sélectionner une.',
                upload_single:
                    'Glissez une photo à uploader ou cliquez pour la sélectionner.',
            },
        },
        banners: {
            name: 'Bannière |||| Bannières',
            fields: {
                name: 'Nom',
                image: 'Image',
                target: 'Cible',
                targetModel: 'Type',
                category: 'Catégorie',
                feature: 'Feature',
            },
            image: {
                upload_several:
                    'Glissez des photos à uploader ou cliquez pour en sélectionner une.',
                upload_single:
                    'Glissez une photo à uploader ou cliquez pour la sélectionner.',
            },
        },
        reviews: {
            name: 'Commentaire |||| Commentaires',
            amount: '1 commentaire |||| %{smart_count} commentaires',
            relative_to_product: 'Commentaire sur',
            detail: 'Détail du commentaire',
            fields: {
                customer_id: 'Client',
                command_id: 'Commande',
                product_id: 'Produit',
                date_gte: 'Publié depuis',
                date_lte: 'Publié avant',
                date: 'Date',
                comment: 'Texte',
                status: 'Statut',
                rating: 'Classement',
            },
            action: {
                accept: 'Accepter',
                reject: 'Rejeter',
            },
            notification: {
                approved_success: 'Commentaire approuvé',
                approved_error: 'Erreur: Commentaire non approuvé',
                rejected_success: 'Commentaire rejeté',
                rejected_error: 'Erreur: Commentaire non rejeté',
            },
        },
        segments: {
            name: 'Segment |||| Segments',
            fields: {
                customers: 'Clients',
                name: 'Nom',
            },
            data: {
                compulsive: 'Compulsif',
                collector: 'Collectionneur',
                ordered_once: 'A commandé',
                regular: 'Régulier',
                returns: 'A renvoyé',
                reviewer: 'Commentateur',
            },
        },
        products: {
            name: 'Produit |||| Produits',
            singular: 'Produit',
            plural: 'Produits',
            fields: {
                id: 'ID',
                title: 'Nom du Produit',
                description: 'Description',
                brand: 'Marque',
                location: 'Position',
                category: 'Catégorie',
                subcategory: 'Sous-catégorie',
                created_by: 'Vendeur',
                created_by_email: 'Vendeur email',
                selled_to: 'Acheteur',
                created_at: "Date d'insertion",
                sellingPrice: 'Prix',
                type: 'Type',
                color: 'Couleur',
                size: 'Taille',
                images: 'Les Photos',
                status: 'État',
                is_published: 'Publié',
                is_featured: 'We Love',
                calls_count: "Nombre d'appels",
                is_good_offer: ' Notre sélection',
                delete_message: 'Motif de suppression',
            },
            fieldGroups: {
                sheet: 'Fiche',
            },
            product_status: {
                title: 'Statut',
                selling: 'En vente',
                selled: 'Vendu',
                featured: 'Mis en avant',
                deleted: 'Supprimé',
                unpublished: 'Non Publié',
                published: ' Publié',
            },
            status: {
                new_with_label: 'Neuf avec étiquettes',
                new_without_label: 'Neuf sans étiquettes',
                very_clean: 'Trés bon état',
                clean: 'Bon état',
                used: 'Usé',
            },
            types: {
                men: 'Hommes',
                women: 'Femmes',
                kids: 'Enfants',
            },
            colors: {
                other: 'Autres',
                multicolor: 'Multi-couleur',
                red: 'Rouge',
                green: 'Vert',
                blue: 'Bleu',
                white: 'Blanc',
                black: 'Noire',
            },
            actions: {
                publish: 'Publier',
                unpublish: 'Masquer',
            },
        },
        events: {
            name: 'Evenement |||| Evenements',
            fields: {
                type: 'Type',
                by: 'Par',
                to: 'Destinataire',
                price: 'Prix',
                created_at: 'Date',
            },
            types: {
                create: 'Création de Produit',
                delete: 'Suppression de produit',
                request_new: 'Nouvelle commande',
                request_decline: 'Déclinaison commande',
                request_accept: 'Acceptation commande',
                request_confirm: 'Confirmation commande',
                request_abondone: 'Abondon de commande',
            },
        },
        slides: {
            name: 'Slide |||| Slides',
            fields: {
                title: 'Titre',
                description: 'Description',
                image: 'Image',
                type: 'Type',
                url: 'Url',
                product: 'Produits',
                is_published: 'Est publié',
                created_at: 'Date de Création',
                updated_at: 'Date de dernière modification',
            },
            type: {
                url: 'Lien Externe',
                product: 'Liste de produits',
            },
            validation: {
                type: {
                    required: 'Ce champs est requis',
                },
                url: {
                    required: 'Ce champs est requis',
                    format: 'Format invalide, vous devez saisir un URL valid',
                },
                products: {
                    required: 'Ce champs est requis',
                    minLength: 'Vous de au moins associer un produit',
                },
            },
        },
        posts: {
            name: 'Article |||| Articles',
            fields: {
                title: 'Titre',
                short_description: 'Description courte',
                content: 'Contenue',
                image: 'Image',
                is_published: 'Publié',
                created_at: 'Date de Création',
            },
        },
        brands: {
            name: 'Marque |||| Marques',
            fields: {
                name: 'Nom',
            },
        },
        admin_messages: {
            name: 'Message |||| Messages',
            fields: {
                type: 'Type',
                message: 'Message',
                is_sent: 'Est envoyé',
                last_sent_at: 'Dernier Envoie',
                slide: 'Slide',
                target: 'Cible',
                users: 'Clients',
                created_at: 'Date de Création',
            },
            types: {
                standard: 'Normal',
                slide: 'Slide',
            },
            targets: {
                all: 'Tout le monde',
                users: 'Clients spécifiques',
            },
            actions: {
                send: 'Diffuser',
                resend: 'Rediffuser',
            },
        },
    },
};

export default customFrenchMessages;
