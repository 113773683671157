import * as React from 'react';
// import { forwardRef } from 'react';
import { AppBar, UserMenu } from 'react-admin';
import Typography from '@material-ui/core/Typography';
// import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },

    logo: {
        height: '48px',
        marginRight: '10px',
    },
});

// const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
//     const translate = useTranslate();
//     return (
//         <MenuItemLink
//             ref={ref}
//             to="/configuration"
//             primaryText={translate('pos.configuration')}
//             leftIcon={<SettingsIcon />}
//             onClick={props.onClick}
//         />
//     );
// });

const CustomUserMenu = (props: any) => (
    <UserMenu {...props}>{/* <ConfigurationMenu /> */}</UserMenu>
);

const CustomAppBar = (props: any) => {
    const classes = useStyles({});
    return (
        <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <img src="./logo.png" alt="Velvetium" className={classes.logo} />
            <span className={classes.spacer} />
        </AppBar>
    );
};

export default CustomAppBar;
