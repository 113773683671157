import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    NumberField,
    Filter,
    List,
    SearchInput,
    downloadCSV,
} from 'react-admin';

import jsonExport from 'jsonexport/dist';
import CustomerLinkField from './CustomerLinkField';
import ColoredBooleanField from '../fields/ColoredBooleanField';

const UserFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const exporter = users => {
    const usersForExport = users.map(user => {
        let birthdate = new Date(user.birthdate).toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        });
        let created_at = new Date(user.created_at).toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        });
        return {
            name: user.name,
            email: user.email,
            phone: user.phone,
            birthdate: birthdate,
            active: user.accountEnabled,
            products: user.count_products.total,
            created_at: created_at,
        };
    });
    jsonExport(usersForExport, {}, (err, csv) => {
        downloadCSV(csv, 'users'); // download as 'posts.csv` file
    });
};

const UserList = (props: any) => {
    return (
        <List
            {...props}
            filters={<UserFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={10}
            exporter={exporter}
            bulkActionButtons={false}
            // aside={<UserListAside />}
        >
            <Datagrid optimized rowClick="edit">
                <CustomerLinkField />
                <TextField source="name" label="resources.users.fields.name" />
                <TextField
                    source="email"
                    label="resources.users.fields.email"
                />
                <TextField
                    source="phone"
                    label="resources.users.fields.phone"
                />
                <DateField
                    source="birthdate"
                    type="date"
                    label="resources.users.fields.birthdate"
                />
                <ColoredBooleanField
                    source="accountEnabled"
                    label="resources.users.fields.accountEnabled"
                />
                <NumberField
                    label="resources.users.fields.count_products"
                    source="count_products.total"
                />
                <DateField
                    source="created_at"
                    type="date"
                    label="resources.users.fields.created_at"
                />
            </Datagrid>
        </List>
    );
};

export default UserList;
