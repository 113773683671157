import * as React from 'react';
import {
    TextInput,
    useTranslate,
    BooleanInput,
    required,
    AutocompleteInput,
    ReferenceInput,
    NumberInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import MyImageInput from '../../inputs/MyImageInput';
import CatInput from '../inputs/CatInput';
import SubCatInput from '../inputs/SubCatInput';
import TypeInput from '../inputs/TypeInput';
import StatusInput from '../inputs/StatusInput';
import ColorInput from '../inputs/ColorInput';
import SizeInput from '../inputs/SizeInput';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
const ProductForm = (props: any) => {
    const translate = useTranslate();

    return (
        <Card>
            <CardContent>
                <Box display={{ md: 'block', lg: 'flex' }}>
                    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                        <Box display="flex">
                            <Box flex={3} mr={{ md: 0, lg: '1em' }}>
                                <Typography variant="h6" gutterBottom>
                                    {translate(
                                        'resources.products.fieldGroups.sheet'
                                    )}
                                </Typography>
                            </Box>
                            <Box flex={1}>
                                <BooleanInput
                                    source="is_published"
                                    label="resources.products.fields.is_published"
                                />
                            </Box>
                            <Box flex={1}>
                                <BooleanInput
                                    source="is_featured"
                                    label="resources.products.fields.is_featured"
                                />
                            </Box>
                            <Box flex={1}>
                                <BooleanInput
                                    source="is_good_offer"
                                    label="resources.products.fields.is_good_offer"
                                />
                            </Box>
                        </Box>

                        <TextInput
                            type="title"
                            source="title"
                            resource="products"
                            label="resources.products.fields.title"
                            validate={[required()]}
                            fullWidth
                        />
                        <RichTextInput
                            source="description"
                            label="resources.products.fields.description"
                        />

                        <Box
                            display={{
                                md: 'block',
                                lg: 'flex',
                            }}
                        >
                            <NumberInput
                                source="sellingPrice"
                                label="resources.products.fields.sellingPrice"
                            />
                            <TextInput
                                label="resources.products.fields.brand"
                                source="brand"
                            />
                        </Box>
                        <Box display={{ md: 'block', lg: 'block' }}>
                            <Box
                                display={{
                                    md: 'block',
                                    lg: 'flex',
                                }}
                            >
                                <StatusInput
                                    source="status"
                                    label="resources.products.fields.status"
                                />
                                <TypeInput
                                    source="type"
                                    label="resources.products.fields.type"
                                />
                                <ColorInput
                                    source="color"
                                    label="resources.products.fields.color"
                                />

                                <SizeInput
                                    source="size"
                                    label="resources.products.fields.size"
                                />
                            </Box>
                            <Box
                                display={{
                                    md: 'block',
                                    lg: 'flex',
                                }}
                            >
                                <CatInput
                                    source="category"
                                    label="resources.products.fields.category"
                                />

                                <SubCatInput
                                    source="subcategory"
                                    label="resources.products.fields.subcategory"
                                />
                            </Box>
                            <Box
                                display={{
                                    md: 'block',
                                    lg: 'flex',
                                }}
                            >
                                <ReferenceInput
                                    label="resources.products.fields.created_by"
                                    source="created_by"
                                    reference="users"
                                >
                                    <AutocompleteInput
                                        optionText="name"
                                        optionValue="id"
                                        disabled
                                    />
                                </ReferenceInput>
                                <ReferenceInput
                                    label="resources.products.fields.created_by_email"
                                    source="created_by"
                                    reference="users"
                                >
                                    <AutocompleteInput
                                        optionText="email"
                                        optionValue="id"
                                        disabled
                                    />
                                </ReferenceInput>
                            </Box>
                            <Box
                                display={{
                                    md: 'block',
                                    lg: 'block',
                                }}
                            >
                                <MyImageInput
                                    accept="image/*"
                                    multiple={true}
                                    source="images"
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default ProductForm;
