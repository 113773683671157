import ShoppingCart from '@material-ui/icons/ShoppingCart';

import ProductList from './ProductList';
import ProductEdit from './ProductEdit';

export default {
    list: ProductList,
    edit: ProductEdit,
    //show: ProductShow,
    icon: ShoppingCart,
};
