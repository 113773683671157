import React, { Fragment } from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    DateField,
    useTranslate,
    Filter,
    SearchInput,
    ReferenceInput,
    AutocompleteInput,
    TextInput,
    Button,
} from 'react-admin';
import Thumbnailfield from '../fields/ThumbnailField';
import TypeField from '../fields/TypeField';
import ProductStatusField from '../fields/ProductStatusField';
import CategoryField from '../fields/CatField';
import CatInput from '../inputs/CatInput';
import SubCatInput from '../inputs/SubCatInput';
import TypeInput from '../inputs/TypeInput';
import StatusInput from '../inputs/StatusInput';
import ColorInput from '../inputs/ColorInput';
import ProductStatusInput from '../inputs/ProductStatusInput';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useFormState } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';

import AddToSlideBtn from '../../slides/inputs/AddToSlideBtn';
const useStyles = makeStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#FFF',
        border: '2px solid #000',
        padding: '20px',
    },
});

// Filters
const ProductFilter = (props: any) => {
    const { filterValues, setFilters } = props;
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
            <TypeInput
                source="type"
                label="resources.products.fields.type"
                onChange={e => {
                    console.log(filterValues);
                    if (filterValues.category)
                        setFilters({
                            ...filterValues,
                            type: e.target.value,
                            category: null,
                            subcategory: null,
                        });
                }}
            />
            <CatInput
                source="category"
                label="resources.products.fields.category"
                emptyText="Tout"
                onChange={e => {
                    if (filterValues.subcategory)
                        setFilters({
                            ...filterValues,
                            category: e.target.value,
                            subcategory: null,
                        });
                }}
            />
            <SubCatInput
                source="subcategory"
                label="resources.products.fields.subcategory"
            />
            <ReferenceInput
                label="resources.products.fields.created_by"
                source="created_by"
                reference="users"
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput
                label="resources.products.fields.selled_to"
                source="selled_to"
                reference="users"
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <TextInput label="resources.products.fields.brand" source="brand" />
            <ProductStatusInput
                source="product_status"
                label="resources.products.product_status.title"
            />
            <StatusInput
                source="status"
                label="resources.products.fields.status"
            />

            <ColorInput
                source="color"
                label="resources.products.fields.color"
            />
        </Filter>
    );
};

const BulkActions = props => (
    <Fragment>
        <AddToSlideBtn label="" {...props} />
    </Fragment>
);

const ProductsSelector = props => {
    const { values } = useFormState();
    const translate = useTranslate();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const classes = useStyles();
    const isSelectable = record => {
        return (
            !values.products ||
            values.products.find(id => id === record.id) == null
        );
    };

    return (
        <>
            <Button label="Choisir des produits" onClick={handleOpen}></Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <List
                            filters={<ProductFilter />}
                            bulkActionButtons={<BulkActions />}
                            basePath="prducts"
                            resource="prducts"
                            hasCreate={false}
                            hasShow={false}
                            hasEdit={false}
                            hasList={false}
                            title={translate('resources.products.plural')}
                            sort={{ field: 'created_at', order: 'DESC' }}
                            perPage={10}
                        >
                            <Datagrid
                                optimized
                                rowClick={null}
                                isRowSelectable={isSelectable}
                            >
                                <Thumbnailfield />
                                <TextField
                                    source="id"
                                    label="resources.products.fields.id"
                                />
                                <TextField
                                    source="title"
                                    label="resources.products.fields.title"
                                />
                                <CategoryField label="resources.products.fields.category" />

                                <ReferenceField
                                    label="resources.products.fields.created_by"
                                    source="created_by"
                                    reference="users"
                                >
                                    <TextField source="name" />
                                </ReferenceField>
                                <DateField source="created_at" type="date" />

                                <TypeField label="resources.products.fields.type" />
                                <ProductStatusField label="resources.products.product_status.title" />
                            </Datagrid>
                        </List>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export default ProductsSelector;
