import * as React from 'react';
import {
    TextInput,
    DateInput,
    PasswordInput,
    useTranslate,
    required,
    email,
    BooleanInput,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

const UserForm = (props: any) => {
    const translate = useTranslate();
    const requiredValidate = [required()];

    return (
        <Card>
            <form>
                <CardContent>
                    <Box display={{ md: 'block', lg: 'flex' }}>
                        <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.users.fieldGroups.identity'
                                )}
                            </Typography>
                            <TextInput
                                source="name"
                                label="resources.users.fields.name"
                                validate={requiredValidate}
                                fullWidth
                            />

                            <TextInput
                                type="email"
                                source="email"
                                label="resources.users.fields.email"
                                validate={[email(), required()]}
                                fullWidth
                            />
                            <TextInput
                                type="phone"
                                source="phone"
                                label="resources.users.fields.phone"
                                validate={requiredValidate}
                                fullWidth
                            />
                            <DateInput
                                type="birthdate"
                                source="birthdate"
                                label="resources.users.fields.birthdate"
                                validate={requiredValidate}
                                fullWidth
                            />

                            <BooleanInput
                                source="accountEnabled"
                                label="resources.users.fields.accountEnabled"
                                fullWidth
                            />

                            <Box mt="1em" />

                            {/* <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.users.fieldGroups.address'
                                        )}
                                    </Typography>
                                    <TextInput
                                        source="address1"
                                        resource="users"
                                        multiline
                                        fullWidth
                                        helperText={false}
                                    />
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="zipcode"
                                                resource="users"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                        <Box
                                            flex={2}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="city"
                                                resource="users"
                                                fullWidth
                                                helperText={false}
                                            />
                                        </Box>
                                    </Box> */}

                            <Box mt="1em" />

                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.users.fieldGroups.change_password'
                                )}
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <PasswordInput
                                        source="password"
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <PasswordInput
                                        source="confirm_password"
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {/* <Box
                                    flex={1}
                                    ml={{ xs: 0, lg: '1em' }}
                                    mt={{ xs: '1em', lg: 0 }}
                                >
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.users.fieldGroups.pricing'
                                        )}
                                    </Typography>

                                    <SelectInput
                                        source="priceCategory"
                                        choices={[
                                            { id: 'A', name: 'tranche A' },
                                            { id: 'B', name: 'tranche B' },
                                            { id: 'C', name: 'tranche C' },
                                            { id: 'D', name: 'tranche D' },
                                        ]}
                                    />

                                </Box> */}
                    </Box>
                </CardContent>
            </form>
        </Card>
    );
};

export default UserForm;
