import * as React from 'react';
import { FC } from 'react';
import PersonAdd from '@material-ui/icons/PersonAdd';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: number;
    label?: string;
}

const NbSubscriptions: FC<Props> = ({ value, label }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/users"
            icon={PersonAdd}
            title={translate(label)}
            subtitle={value ? `${value} ` : '--'}
        />
    );
};

export default NbSubscriptions;
