import * as React from 'react';
import { FC } from 'react';
import Chip from '@material-ui/core/Chip';
import { useTranslate } from 'react-admin';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { Record } from 'ra-core';
interface ProductProps {
    record?: Record;
    basePath?: string;
    label?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        deleted: {
            color: 'white',
        },
        unpublished: {
            color: 'white',
            backgroundColor: 'gray',
        },
        selled: {
            color: 'white',
            backgroundColor: 'green',
        },
        selling: {
            color: 'white',
            backgroundColor: 'orange',
        },
    })
);
const ProductStatusField: FC<ProductProps> = ({ record }) => {
    const translate = useTranslate();

    const classes = useStyles({});
    if (record) {
        return (
            <Chip
                size="small"
                label={translate(
                    `resources.products.product_status.${record.product_status}`
                )}
                className={classes[record.product_status]}
            />
        );
    } else return null;
};
export default ProductStatusField;
