import React, { ComponentType } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { ChipField } from 'react-admin';
import { FieldProps } from '../../types';
// import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
        url: {
            color: '#570318',
            borderColor: '#570318',
        },
        product: {
            color: '#227e68',
            borderColor: '#227e68',
        },
    })
);

const colored = <T extends FieldProps>(
    WrappedComponent: ComponentType<T>
): ComponentType<T> => {
    const Colored = (props: T) => {
        const classes = useStyles({});
        return props.record && props.source ? (
            props.record[props.source] === 'url' ? (
                <Chip
                    size="small"
                    label="Lien"
                    variant="outlined"
                    className={classes.url}
                />
            ) : (
                <Chip
                    size="small"
                    label="Produits"
                    variant="outlined"
                    className={classes.product}
                />
            )
        ) : null;
    };

    Colored.displayName = `Colored(${WrappedComponent.displayName})`;

    return Colored;
};

const TypeField = colored<typeof ChipField>(ChipField);
TypeField.defaultProps = ChipField.defaultProps;

export default TypeField;
