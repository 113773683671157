import * as React from 'react';
import { SelectInput } from 'react-admin';

import config from '../config';
const toChoices = items =>
    items.map(item => ({
        id: item.name,
        name: item.label,
        value: item.value,
    }));

const ColorInput = props => {
    if (!config) return <SelectInput choices={[]} {...props} />;
    return (
        <SelectInput
            choices={toChoices(config.colors)}
            {...props}
            emptyText="Tout"
        />
    );
};

export default ColorInput;
