import * as React from 'react';
import {
    Button,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';
import { Visibility } from '@material-ui/icons';

const UnpublishBulkButton = ({ selectedIds }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany, { loading }] = useUpdateMany(
        'prducts/bulk',
        selectedIds,
        selectedIds.map(id => {
            return { id, is_published: false };
        }),
        {
            onSuccess: () => {
                console.log('success');
                notify('Products updated');
                unselectAll();
                refresh();
            },
            onFailure: error =>
                notify('Error: products not updated', 'warning'),
        }
    );

    return (
        <Button
            label="resources.products.actions.unpublish"
            disabled={loading}
            onClick={updateMany}
        >
            <Visibility />
        </Button>
    );
};

export default UnpublishBulkButton;
