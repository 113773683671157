import * as React from 'react';
import { FC } from 'react';
import {
    Edit,
    Toolbar,
    SaveButton,
    TextField,
    TabbedForm,
    FormTab,
} from 'react-admin';

import Aside from './Aside';
import ProductHistory from './parts/ProductHistory';
import ProductForm from './parts/ProductForm';
import { Product, FieldProps } from '../types';
const ProductToolbar = props => (
    <Toolbar {...props}>
        <SaveButton redirect="list" submitOnEnter={true} />
    </Toolbar>
);
const ProductEdit = (props: any) => {
    /* return {
        <TabbedShowLayout {...props}>
            <Tab label="resources.products.fieldGroups.sheet" {...props}>
                <Edit
                    title={<Producttitle source="title" />}
                    aside={<Aside />}
                    undoable={false}
                    component="div"
                    {...props}
                >
                    <ProductForm />
                </Edit>
            </Tab>
            <Tab label="Hitorique" path="history" {...props}>
                <ProductHistory id={props.id} />
            </Tab>
    </TabbedShowLayout>
    };*/
    return (
        <Edit
            title={<Producttitle source="title" />}
            aside={<Aside />}
            undoable={false}
            component="div"
            {...props}
        >
            <TabbedForm toolbar={<ProductToolbar />}>
                <FormTab label="resources.products.fieldGroups.sheet">
                    <ProductForm />
                </FormTab>
                <FormTab label="Hitorique" path="history">
                    <ProductHistory id={props.id} />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

const Producttitle: FC<FieldProps<Product>> = ({ record }) =>
    record ? <TextField record={record} size="32" /> : null;

export default ProductEdit;
