import React, { useState, useEffect, useCallback, FC } from 'react';
import { useVersion, useDataProvider, useTranslate } from 'react-admin';
import { Card, CardContent, Typography } from '@material-ui/core';

import NbSubscriptions from './NbSubscriptions';
import NbProducts from './NbProoducts';
import Revenue from './Revenue';

interface State {
    nbTodaySubscriptions?: number;
    nbThisWeekSubscriptions?: number;
    nbThisMonthSubscriptions?: number;

    nbTodayProducts?: number;
    nbThisWeekProducts?: number;
    nbThisMonthProducts?: number;

    nbTodaySelles?: number;
    nbThisWeekSelles?: number;
    nbThisMonthSelles?: number;

    todayCA?: number;
    thisWeekCA?: number;
    thisMonthCA?: number;
}

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
    sectiontitle: { fontSize: 14 },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard: FC = () => {
    const [state, setState] = useState<State>({});
    const version = useVersion();
    const dataProvider = useDataProvider();
    const translate = useTranslate();

    // build dates
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    var day = today.getDay();
    const diff = today.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    const lastMonday = new Date(new Date().setDate(diff));
    lastMonday.setHours(0, 0, 0, 0);

    const fetchUsers = useCallback(async () => {
        let query = {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 1 },
            select: 'name',
        };
        // Get Today's subscriptions
        query.filter = { date_gte: today.toISOString() };
        const { total: nbTodaySubscriptions } = await dataProvider.getList(
            'users',
            query
        );
        // get this months subscriptions
        query.filter = { date_gte: firstDayOfMonth.toISOString() };
        const { total: nbThisMonthSubscriptions } = await dataProvider.getList(
            'users',
            query
        );
        // Get this week's subscriptions
        query.filter = { date_gte: lastMonday.toISOString() };
        const { total: nbThisWeekSubscriptions } = await dataProvider.getList(
            'users',
            query
        );

        setState(state => ({
            ...state,
            nbTodaySubscriptions,
            nbThisWeekSubscriptions,
            nbThisMonthSubscriptions,
        }));
    }, [dataProvider, today, firstDayOfMonth, lastMonday]);

    const fetchProducts = useCallback(async () => {
        let query = {
            filter: {},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 1 },
            select: 'title',
        };
        // Get Today's Products
        query.filter = { created_at_gte: today.toISOString() };
        const { total: nbTodayProducts } = await dataProvider.getList(
            'prducts',
            query
        );
        // get this months Products
        query.filter = { created_at_gte: firstDayOfMonth.toISOString() };
        const { total: nbThisMonthProducts } = await dataProvider.getList(
            'prducts',
            query
        );
        // Get this week's Products
        query.filter = { created_at_gte: lastMonday.toISOString() };
        const { total: nbThisWeekProducts } = await dataProvider.getList(
            'prducts',
            query
        );

        setState(state => ({
            ...state,
            nbTodayProducts,
            nbThisWeekProducts,
            nbThisMonthProducts,
        }));
    }, [dataProvider, today, firstDayOfMonth, lastMonday]);

    const fetchSelles = useCallback(async () => {
        let query = {
            filter: {},
            sort: { field: 'selled_at', order: 'DESC' },
            pagination: { page: 1, perPage: 1000 },
            select: 'selled_price',
        };
        // Get Today's Selles
        query.filter = { selled_at_gte: today.toISOString() };
        const {
            total: nbTodaySelles,
            data: todaySelles,
        } = await dataProvider.getList('prducts', query);
        let todayCA = 0;
        todaySelles.forEach(p => {
            todayCA += p.selled_price;
        });
        // get this months Selles
        query.filter = { selled_at_gte: firstDayOfMonth.toISOString() };
        const {
            total: nbThisMonthSelles,
            data: thisMonthSelles,
        } = await dataProvider.getList('prducts', query);
        let thisMonthCA = 0;
        thisMonthSelles.forEach(p => {
            thisMonthCA += p.selled_price;
        });
        // Get this week's Selles
        query.filter = { selled_at_gte: lastMonday.toISOString() };
        const {
            total: nbThisWeekSelles,
            data: thisWeekSelles,
        } = await dataProvider.getList('prducts', query);
        
        let thisWeekCA = 0;
        thisWeekSelles.forEach(p => {
            thisWeekCA += p.selled_price;
        });
        setState(state => ({
            ...state,
            nbTodaySelles,
            nbThisWeekSelles,
            nbThisMonthSelles,
            todayCA,
            thisWeekCA,
            thisMonthCA,
        }));
    }, [dataProvider, today, firstDayOfMonth, lastMonday]);
    useEffect(() => {
        fetchUsers();
        fetchProducts();
        fetchSelles();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {/*<Welcome />*/}
            <Card>
                <CardContent>
                    <Typography variant="h4" component="h4">
                        {translate('pos.dashboard.subscriptions.title')}
                    </Typography>
                    <div style={styles.flex}>
                        <NbSubscriptions
                            label="pos.dashboard.subscriptions.today"
                            value={state.nbTodaySubscriptions}
                        />
                        <Spacer />
                        <NbSubscriptions
                            label="pos.dashboard.subscriptions.week"
                            value={state.nbThisWeekSubscriptions}
                        />
                        <Spacer />
                        <NbSubscriptions
                            label="pos.dashboard.subscriptions.month"
                            value={state.nbThisMonthSubscriptions}
                        />
                    </div>
                </CardContent>
            </Card>
            <VerticalSpacer />
            <Card>
                <CardContent>
                    <Typography variant="h4" component="h4">
                        {translate('pos.dashboard.products.title')}
                    </Typography>
                    <div style={styles.flex}>
                        <NbProducts
                            label="pos.dashboard.products.today"
                            value={state.nbTodayProducts}
                        />
                        <Spacer />
                        <NbProducts
                            label="pos.dashboard.products.week"
                            value={state.nbThisWeekProducts}
                        />
                        <Spacer />
                        <NbProducts
                            label="pos.dashboard.products.month"
                            value={state.nbThisMonthProducts}
                        />
                    </div>
                </CardContent>
            </Card>
            <VerticalSpacer />
            <Card>
                <CardContent>
                    <Typography variant="h4" component="h4">
                        {translate('pos.dashboard.selles.title')}
                    </Typography>
                    <div style={styles.flex}>
                        <NbProducts
                            label="pos.dashboard.selles.today"
                            value={state.nbTodaySelles}
                        />
                        <Spacer />
                        <NbProducts
                            label="pos.dashboard.selles.week"
                            // value={state.nbThisMonthSelles}
                            value={state.nbThisWeekSelles}
                        />
                        <Spacer />
                        <NbProducts
                            label="pos.dashboard.selles.month"
                            value={state.nbThisMonthSelles}
                        />
                    </div>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Typography variant="h4" component="h4">
                        {translate('pos.dashboard.ca.title')}
                    </Typography>
                    <div style={styles.flex}>
                        <Revenue
                            label="pos.dashboard.ca.today"
                            value={state.todayCA}
                        />
                        <Spacer />
                        <Revenue
                            label="pos.dashboard.ca.week"
                            value={state.thisWeekCA}
                        />
                        <Spacer />
                        <Revenue
                            label="pos.dashboard.ca.month"
                            value={state.thisMonthCA}
                        />
                    </div>
                </CardContent>
            </Card>
        </>
    );
};

export default Dashboard;
