import * as React from 'react';
import { FC } from 'react';
import { ProductEvent, FieldProps } from '../../types';
import {
    AddCircle,
    DeleteForever,
    AddShoppingCart,
    CheckCircle,
    PhoneInTalk,
    Backspace,
    Cancel,
} from '@material-ui/icons';
import { green, red, orange } from '@material-ui/core/colors';

interface Props extends FieldProps<ProductEvent> {
    className?: string;
    size?: string;
}
const EventTYpeField: FC<Props> = ({ record, size = '25', className }) => {
    if (!record) return null;
    let icon;
    switch (record.type) {
        case 'create':
            icon = <AddCircle color="primary" />;
            break;
        case 'delete':
            icon = <DeleteForever color="secondary" />;
            break;
        case 'request_new':
            icon = <AddShoppingCart color="action" />;
            break;
        case 'request_accept':
            icon = <PhoneInTalk style={{ color: orange[500] }} />;
            break;
        case 'request_decline':
            icon = <Cancel style={{ color: red[500] }} />;
            break;
        case 'request_confirm':
            icon = <CheckCircle style={{ color: green[500] }} />;
            break;
        case 'request_abondone':
            icon = <Backspace style={{ color: red[500] }} />;
            break;
    }
    return <div>{icon}</div>;
};

export default EventTYpeField;
