import * as React from 'react';
import {
    Button,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';
import { VisibilityOff } from '@material-ui/icons';

const PublishBulkButton = ({ selectedIds }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const data = selectedIds.map(id => {
        return { id, is_published: true };
    });
    const [updateMany, { loading }] = useUpdateMany(
        'prducts/bulk',
        selectedIds,
        data,
        {
            onSuccess: () => {
                console.log('success');
                refresh();
                notify('Products updated');
                unselectAll();
            },
            onFailure: error => notify('Error: posts not updated', 'warning'),
        }
    );

    return (
        <Button
            label="resources.products.actions.publish"
            disabled={loading}
            onClick={updateMany}
        >
            <VisibilityOff />
        </Button>
    );
};

export default PublishBulkButton;
